import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Heading from "./heading"

import { colors } from "./globalStyle"

import styled from "styled-components"
const StyledDiv = styled.div`
  display: flex;
  margin: 1rem 0;

  h1 {
    font-family: Maven Pro;
    font-weight: 500 !important;
    color: ${colors.primary};
    margin: 0;
    padding-bottom: 1rem;
    text-transform: uppercase;
    border-bottom: 2px solid currentColor;
    letter-spacing: 2px;
  }
  span {
    height: 1px;
    border-radius: 10px;
    border: 1px solid ${colors.blueGrey};
    background: ${colors.primary};
    width: 25vh;
    margin: 0 1rem;
  }

  h2 {
    font-weight: 600 !important;
    color: ${colors.blueGrey};
    font-family: Maven Pro;

    padding: 0;
    margin: 0;
  }

  @media (max-width: 910px) {
    h1 {
      font-size: 1.6rem;
    }
    h2 {
      font-size: 1.2rem;
    }
    h3 {
      font-size: 0.4rem;
    }
    span {
      width: 10vh !important;
    }
  }
  @media (max-width: 668px) {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 1.1rem;
    }
    h3 {
      font-size: 0.5rem;
    }
    span {
      width: 10vh !important;
    }
  }
  @media (max-width: 340px) {
    span {
      display: none;
    }
  }
`

const GlobalHeading = ({ primary, secondary }) => {
  return (
    <StyledDiv>{primary ? <h1>{primary}</h1> : <h2>{secondary}</h2>}</StyledDiv>
  )
}

GlobalHeading.propTypes = {}

GlobalHeading.defaultProps = {}

export default GlobalHeading
