import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import styled from "styled-components"
//import "./heading.css"
import Button from "./button"

const MovingSection = styled.section`
  h1 {
    font-family: "Maven Pro" !important;
    font-weight: 900 !important;
  }

  p {
  }
`

const Heading = () => (
  <MovingSection className="wrapper">
    <h1 className="sentence">
      Dobrý deň prajem, <br />
      vitajte na
      <div className="slidingVertical">
        <span>webe</span>
        <span>webstranke</span>
        <span>nasej webstranke.</span>
      </div>
      <br />
      digitálnej agentúry
    </h1>
    <p>
      webdesign webpages development apps <br />
      hardware solutions ui/ux design
    </p>
    <Button text="take a tour" primary linkTo={"/sucess"} />
  </MovingSection>
)

export default Heading
