import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from 'styled-components'

const StyledButton = styled.div`
a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  :hover{
    color: red;
    box-shadow: 0px 0px 84px -20px rgba(0,39,94,1);
  }
}
`

const primary = {
  color: `white`,
  textDecoration: `none`,
  background: '#16b1ff',
  padding: '0.7rem  0.9rem',
  borderRadius: '0.75rem' 
}

const secondary = {
  color: `#16b1ff`,
  textDecoration: `none`,
  background: 'white',
  padding: '0.55rem  0.75rem',
  borderRadius: '0.75rem',
  border: '0.15rem solid' 
}

export default (props) => (
    <StyledButton>
    <Link
      to={props.linkTo}
      style={!props.primary ? primary : secondary }
    >
      {props.text}
    </Link>
  </StyledButton>
)