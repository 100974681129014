import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Heading from './heading';
import Scrollspy from 'react-scrollspy';
import Scroll from './scroll';
import logo from '../images/logo.png';

import MailOutline from '@material-ui/icons/MailOutline';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

import { colors } from './globalStyle';

import styled from 'styled-components';

const StyledDiv = styled.div`
  font-family: 'Maven Pro' !important;
  padding: 0;
  background: ${colors.blueGrey};
  width: 100%;

  .main {
    padding: 2rem 7rem;
    display: flex;
    justify-content: space-between;
    nav {
      width: 40%;

      ul {
        margin: 0;
        list-style-type: none;
        li {
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
          a {
            text-align: left;

            font-size: 0.6rem;
            line-height: 0.6rem;
            text-decoration: none;
            color: ${colors.primary};
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-weight: 600;
          }
        }
      }
    }
    .logo-pic {
      width: 20%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;

      img {
        max-height: 10vw;
        height: 10vw;
      }
    }
    article {
      height: 100%;
      width: 40%;
      h4 {
        text-align: right;
        font-size: 0.6rem;
        line-height: 1.3rem;
        text-decoration: none;
        color: ${colors.primary};
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 600;
      }
      .bottom-header {
        margin-bottom: 0;
        opacity: 0.4;
        span {
          cursor: pointer;
          a {
            color: ${colors.primary};
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    section {
      padding: 1rem 5rem !important;
    }
  }
  @media (max-width: 910px) {
    section {
      padding: 1rem 3rem !important;
    }
  }
  @media (max-width: 570px) {
    nav {
      display: none;
    }
    .logo-pic {
      width: 50% !important;

      img {
        max-height: 25vw !important;
        height: 25vw !important;
      }
    }
    .bottom-header {
      margin-left: -50vw;
    }
  }
  @media (max-width: 768px) {
    padding-top: 1rem !important;
    section {
      padding: 1rem 2rem !important;
    }
  }
`;
const Footer = ({ siteTitle }) => (
  <StyledDiv>
    <section className="main">
      <nav>
        <Scrollspy items={['main']} currentClassName="is-active" offset={-300}>
          <li>
            <Scroll type="id" element="about-us">
              <a href="#">Naše služby</a>
            </Scroll>
          </li>

          <li>
            <Scroll type="id" element="price-list">
              <a href="#">Cenník</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="opening-hours">
              <a href="#">Otváracie hodiny</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="where-we-are">
              <a href="#">Kontakt</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="say hello">
              <a href="#">Napíšte nám</a>
            </Scroll>
          </li>
        </Scrollspy>
      </nav>
      <div className="logo-pic">
        <img src={logo} />
      </div>
      <article>
        <h4>
          +421 902 875 707<br></br>
          Nám. gen. Štefánika 4 (budova pošty)<br></br>
          1.poschodie, č.dverí 122,<br></br>
          Stará Ľubovňa
        </h4>
        <h4 className="bottom-header">
          {new Date().getFullYear()} Kaderníctvo crop
          <br />
          <span>
            <a href="http://acsella.com/" target="_blank">
              design + code acsella
            </a>
          </span>
        </h4>
      </article>
    </section>
  </StyledDiv>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
